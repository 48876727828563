<template>
  <div
    id="pum-5858"
    role="dialog"
    aria-modal="true"
    class="pum pum-overlay pum-theme-5850 pum-theme-lightbox popmake-overlay auto_open click_open pum-active"
    data-popmake='{"id":5858,"slug":"newsletter","theme_id":5850,"cookies":[{"event":"on_popup_close","settings":{"name":"pum-5858","key":"","session":false,"path":"1","time":"1 month"}}],"triggers":[{"type":"auto_open","settings":{"cookie_name":["pum-5858"],"delay":"500"}},{"type":"click_open","settings":{"extra_selectors":"","cookie_name":null}}],"mobile_disabled":null,"tablet_disabled":null,"meta":{"display":{"stackable":false,"overlay_disabled":false,"scrollable_content":false,"disable_reposition":false,"size":"custom","responsive_min_width":"%","responsive_min_width_unit":false,"responsive_max_width":"%","responsive_max_width_unit":false,"custom_width":"px","custom_width_unit":false,"custom_height":"px","custom_height_unit":false,"custom_height_auto":false,"location":"center","position_from_trigger":false,"position_top":"100","position_left":"0","position_bottom":"0","position_right":"0","position_fixed":false,"animation_type":"fade","animation_speed":"350","animation_origin":"center top","overlay_zindex":false,"zindex":"1999999999"},"close":{"text":"","button_delay":"0","overlay_click":false,"esc_press":false,"f4_press":false},"click_open":[]}}'
  >
    <div
      id="popmake-5858"
      class="pum-container popmake theme-5850 size-custom active custom-position"
      style="top: 393px; left: 15.8125px; opacity: 1; display: block"
    >
      <div class="pum-content popmake-content" tabindex="0">
        <p>
          <iframe
            src="https://embeds.beehiiv.com/145e47a2-7b43-4243-917f-3f5ff5674e12"
            data-test-id="beehiiv-embed"
            width="480"
            height="320"
            frameborder="0"
            scrolling="no"
            style="border-radius: 4px; margin: 0; background-color: transparent"
          ></iframe>
        </p>
      </div>

      <button type="button" class="pum-close popmake-close" @click="changeStatus" aria-label="Close">
        ×
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
const status = ref(false)

const changeStatus = ()=>{
  status.value = !status.value
  console.log(status.value)
}
const isActive = ref(false)
const pum5858 = localStorage.getItem('pum-5858')
isActive.value = pum5858 ? false: true;
</script>

<style lang="scss" scoped>
.custom-position .pum-content.popmake-content {
  margin-left: 19%;
}

.pum-theme-5850 .pum-title,
.pum-theme-lightbox .pum-title {
  color: #fff;
  text-align: center;
  font-family: "Inter";
  font-weight: 400;
  font-size: 50px;
  line-height: 56px;
}

#mc_embed_signup .mc-field-group input {
  width: 100%;
}

#mc_embed_signup_scroll p {
  font-size: 18px !important;
  line-height: 24px;
  font-family: "Inter";
  text-align: center;
  color: #fff !important;
  margin-bottom: 40px;
}

.optionalParent input {
  color: #fff !important;
}

.mc-field-group label {
  display: none !important;
}

#mc_embed_signup {
  width: 100% !important;
}

.optionalParent img.refferal_badge,
.indicates-required,
#mce-responses {
  display: none !important;
}

#mc_embed_signup .button:hover {
  background-color: #000 !important;
}

#mc_embed_signup {
  background: transparent !important;
  width: 627px !important;
  margin: 0 auto !important;
}

#mc_embed_signup .mc-field-group input {
  border: 1px solid #e9ac2a !important;
  border-radius: 8px;
  padding: 15px !important;
  background-color: transparent !important;
  color: #fff !important;
}

#mc_embed_signup .mc-field-group {
  width: 60% !important;
  display: block !important;
  float: left;
  margin-left: 20px;
}

#mc_embed_signup .mc-field-group input::placeholder {
  font-family: "Inter";
  color: #fff !important;
}

.optionalParent {
  width: 33%;
  float: right;
  position: relative;
  display: block;
}

.optionalParent:before {
  position: absolute;
  content: "";
  background-image: url(https://larkfunding.com/wp-content/uploads/2023/07/arrow-hero-icon.svg);
  background-repeat: no-repeat;
  width: 33px;
  height: 15px;
  top: 20px;
  right: 37px;
  background-position: center;
  z-index: 1;
}

#mc_embed_signup .button {
  height: 57px !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 24px !important;
  padding: 0px 60px 0 20px !important;
  text-transform: capitalize;
  border-radius: 8px !important;
  background: linear-gradient(94deg, #e9ac2a 0%, #e9ac2a 100%);
}

.pum-theme-5850 .pum-container,
.pum-theme-lightbox .pum-container {
  padding: 100px 100px 100px 50px !important;
  border-radius: 10px;
  border: 1px solid rgba(255, 206, 0, 0.4);
  background: #151616;
  position: relative;
  max-width: 840px;
  width: 100%;
  min-height: 505px;
  margin: 0 auto !important;
  left: 0 !important;
}

button.pum-close.popmake-close {
  height: 45px !important;
  width: 45px !important;
  color: #fff !important;
  border: 1px solid #e9ac2a91 !important;
  border-radius: 0 !important;
}

.pum-theme-5850 .pum-container:before {
  position: absolute;
  content: "";
  width: 87px;
  height: 96px;
  background-image: url(https://larkfunding.com/wp-content/uploads/2023/01/Icon.png);
  background-repeat: no-repeat;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  right: 0;
}

.mce_inline_error {
  padding: 10px 20px !important;
  background: #fff !important;
}


html.pum-open.pum-open-overlay,html.pum-open.pum-open-overlay.pum-open-fixed .pum-overlay {
    overflow: hidden
}

html.pum-open.pum-open-overlay.pum-open-scrollable body>:not([aria-modal=true]) {
    padding-right: 15px
}

html.pum-open.pum-open-overlay.pum-open-scrollable .pum-overlay.pum-active {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch
}

html.pum-open.pum-open-overlay-disabled.pum-open-scrollable .pum-overlay.pum-active {
    position: static;
    height: auto;
    width: auto
}
</style>