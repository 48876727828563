<template>

  <header id="masthead" itemscope="itemscope" itemtype="https://schema.org/WPHeader">
    <p class="main-title bhf-hidden" itemprop="headline"><a href="https://larkfunding.com" title="STARFI"
        rel="home">STARFI</a></p>
    <div data-elementor-type="wp-post" data-elementor-id="10" class="elementor elementor-10">
      <section
        class="elementor-section elementor-top-section elementor-element elementor-element-dc80b44 site-header elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="dc80b44" data-element_type="section"
        data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
        <div class="elementor-container elementor-column-gap-default">
          <div
            class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-bf6a459"
            data-id="bf6a459" data-element_type="column">
            <div class="elementor-widget-wrap elementor-element-populated">
              <div class="elementor-element elementor-element-0990357 elementor-widget elementor-widget-image"
                data-id="0990357" data-element_type="widget" data-widget_type="image.default">
                <div class="elementor-widget-container">
                  <a href="/">
                    <img width="317" height="72"
                      src="@/assets/images/logo.png"
                      class="attachment-full size-full wp-image-5485" alt="" /> </a>
                </div>
              </div>
            </div>
          </div>
          <div
            class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-a168264"
            data-id="a168264" data-element_type="column">
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="elementor-element elementor-element-9815ae6 main-menu-wrap programs-dropdown uael-submenu-icon-classic uael-nav-menu__align-right elementor-widget__width-auto elementor-hidden-tablet elementor-hidden-mobile uael-submenu-open-hover uael-submenu-animation-none uael-link-redirect-child uael-nav-menu__breakpoint-tablet uael-nav-menu-toggle-label-no elementor-widget elementor-widget-uael-nav-menu"
                data-id="9815ae6" data-element_type="widget"
                data-settings="{&quot;distance_from_menu&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-40,&quot;sizes&quot;:[]},&quot;distance_from_menu_tablet&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;distance_from_menu_mobile&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]}}"
                data-widget_type="uael-nav-menu.default">
                <div class="elementor-widget-container">
                  <div class="uael-nav-menu uael-layout-horizontal uael-nav-menu-layout uael-pointer__none"
                    data-layout="horizontal">
                    <div role="button" class="uael-nav-menu__toggle elementor-clickable">
                      <span class="screen-reader-text">Main Menu</span>
                      <div class="uael-nav-menu-icon">
                        <i aria-hidden="true" class="fas fa-align-justify"></i>
                      </div>
                    </div>
                    <nav class="uael-nav-menu__layout-horizontal uael-nav-menu__submenu-classic"
                      data-toggle-icon="&lt;i aria-hidden=&quot;true&quot; class=&quot;fas fa-align-justify&quot;&gt;&lt;/i&gt;"
                      data-close-icon="&lt;i aria-hidden=&quot;true&quot; class=&quot;far fa-window-close&quot;&gt;&lt;/i&gt;"
                      data-full-width="yes">
                      <ul id="menu-1-9815ae6" class="uael-nav-menu">
                        <li id="menu-item-4557"
                          :class="[path=='/'?'current-menu-item':'']"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home page_item page-item-3925 current_page_item parent uael-creative-menu">
                          <a href="/" class="uael-menu-item">Home</a>
                        </li>
                        <li id="menu-item-3582" v-if="false"
                          class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children parent uael-has-submenu uael-creative-menu">
                          <div class="uael-has-submenu-container"><a aria-expanded="false" href="#"
                              class="uael-menu-item">Programs<span
                                class='uael-menu-toggle sub-arrow uael-menu-child-0'><i class="fa fa-solid fa-sort-down"></i></span></a></div>
                          <ul class="sub-menu">
                            <li id="menu-item-3637"
                              class="menu-item menu-item-type-post_type menu-item-object-page uael-creative-menu"><a
                                href="/1-stage-evaluation/" class="uael-sub-menu-item child-menu-item">1-Stage
                                Evaluation</a></li>
                            <li id="menu-item-3583"
                              class="menu-item menu-item-type-post_type menu-item-object-page uael-creative-menu"><a
                                href="/2-stage-evaluation/" class="uael-sub-menu-item child-menu-item">2-Stage
                                Evaluation</a></li>
                            <li id="menu-item-4685"
                              class="menu-item menu-item-type-post_type menu-item-object-page uael-creative-menu"><a
                                href="/3-stage/" class="uael-sub-menu-item child-menu-item">3-Stage
                                Evaluation</a></li>
                          </ul>
                        </li>
                        <li id="menu-item-4749"
                          :class="[path=='/3-stage/'?'current-menu-item':'']"
                          class="menu-item menu-item-type-post_type menu-item-object-page parent uael-creative-menu"><a
                            href="/3-stage/" class="uael-menu-item">3-Stage Program</a></li>
                        <li id="menu-item-3020"
                          :class="[path=='/faq/'?'current-menu-item':'']"
                          class="menu-item menu-item-type-custom menu-item-object-custom parent uael-creative-menu"><a
                            href="/faq/" class="uael-menu-item">FAQ</a></li>
                        <li id="menu-item-3088"
                          :class="[path=='/about-us/'?'current-menu-item':'']"
                          class="menu-item menu-item-type-custom menu-item-object-custom parent uael-creative-menu"><a
                            href="/about-us/" class="uael-menu-item">About Us</a></li>
                        <li id="menu-item-3027"
                          :class="[path=='/contact-us/'?'current-menu-item':'']"
                          class="menu-item menu-item-type-custom menu-item-object-custom parent uael-creative-menu"><a
                            href="/contact-us/" class="uael-menu-item">Contact Us</a></li>
                        <li id="menu-item-3028"
                          :class="[path=='/activity/'?'current-menu-item':'']"
                          class="menu-item menu-item-type-custom menu-item-object-custom parent uael-creative-menu"><a
                            href="/activity/" class="uael-menu-item">Activity Content</a></li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
              <div
                class="elementor-element elementor-element-afaa2ee elementor-widget__width-auto button-new head-btn elementor-hidden-mobile elementor-widget elementor-widget-button"
                data-id="afaa2ee" data-element_type="widget" data-widget_type="button.default">
                <div class="elementor-widget-container">
                  <div class="elementor-button-wrapper">
                    <a class="elementor-button elementor-button-link elementor-size-sm"
                      href="https://www.starfinland.com/#/pages/auth/login">
                      <span class="elementor-button-content-wrapper">
                        <span class="elementor-button-icon">
                          <svg xmlns="http://www.w3.org/2000/svg" width="31" height="15" viewBox="0 0 31 15"
                            fill="none">
                            <path d="M23.3047 2.2373L28.5847 7.72822L23.3047 13.2191" stroke="white" stroke-width="3"
                              stroke-linecap="round" stroke-linejoin="round"></path>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M0.777344 7.72851C0.777344 7.0546 1.32455 6.5083 1.99957 6.5083H27.3607C28.0357 6.5083 28.5829 7.0546 28.5829 7.72851C28.5829 8.40241 28.0357 8.94871 27.3607 8.94871H1.99957C1.32455 8.94871 0.777344 8.40241 0.777344 7.72851Z"
                              fill="white"></path>
                          </svg> </span>
                        <span class="elementor-button-text">Login</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div
                class="elementor-element elementor-element-70e38a3 uael-nav-menu__align-center elementor-widget__width-auto elementor-widget-tablet__width-initial elementor-hidden-desktop uael-submenu-open-hover uael-submenu-icon-arrow uael-submenu-animation-none uael-link-redirect-child uael-nav-menu__breakpoint-tablet uael-nav-menu-toggle-label-no elementor-widget elementor-widget-uael-nav-menu"
                data-id="70e38a3" data-element_type="widget"
                data-settings="{&quot;distance_from_menu_tablet&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:10,&quot;sizes&quot;:[]},&quot;distance_from_menu&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;distance_from_menu_mobile&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]}}"
                data-widget_type="uael-nav-menu.default">
                <div class="elementor-widget-container">
                  <div class="uael-nav-menu uael-layout-horizontal uael-nav-menu-layout uael-pointer__none"
                    data-layout="horizontal">
                    <div role="button" class="uael-nav-menu__toggle elementor-clickable">
                      <span class="screen-reader-text">Main Menu</span>
                      <div class="uael-nav-menu-icon">
                        <i aria-hidden="true" class="fas fa-bars"></i>
                      </div>
                    </div>
                    <nav class="uael-nav-menu__layout-horizontal uael-nav-menu__submenu-arrow"
                      data-toggle-icon="&lt;i aria-hidden=&quot;true&quot; class=&quot;fas fa-bars&quot;&gt;&lt;/i&gt;"
                      data-close-icon="&lt;i aria-hidden=&quot;true&quot; class=&quot;fas fa-times&quot;&gt;&lt;/i&gt;"
                      data-full-width="yes">
                      <ul id="menu-1-70e38a3" class="uael-nav-menu">
                        <li id="menu-item-4557"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-3925 current_page_item parent uael-creative-menu">
                          <a href="/" class="uael-menu-item">Home</a>
                        </li>
                        <li id="menu-item-3582" v-if="false"
                          class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children parent uael-has-submenu uael-creative-menu">
                          <div class="uael-has-submenu-container"><a aria-expanded="false" href="#"
                              class="uael-menu-item">Programs<span
                                class='uael-menu-toggle sub-arrow uael-menu-child-0'><i class='fa fa-solid fa-sort-down'></i></span></a></div>
                          <ul class="sub-menu">
                            <li id="menu-item-3637"
                              class="menu-item menu-item-type-post_type menu-item-object-page uael-creative-menu"><a
                                href="/1-stage-evaluation/" class="uael-sub-menu-item">1-Stage
                                Evaluation</a></li>
                            <li id="menu-item-3583"
                              class="menu-item menu-item-type-post_type menu-item-object-page uael-creative-menu"><a
                                href="/2-stage-evaluation/" class="uael-sub-menu-item">2-Stage
                                Evaluation</a></li>
                            <li id="menu-item-4685"
                              class="menu-item menu-item-type-post_type menu-item-object-page uael-creative-menu"><a
                                href="/3-stage/" class="uael-sub-menu-item">3-Stage
                                Evaluation</a></li>
                          </ul>
                        </li>
                        <li id="menu-item-4749"
                          class="menu-item menu-item-type-post_type menu-item-object-page parent uael-creative-menu"><a
                            href="/3-stage/" class="uael-menu-item">3-Stage Program</a></li>
                        <li id="menu-item-3020"
                          class="menu-item menu-item-type-custom menu-item-object-custom parent uael-creative-menu"><a
                            href="/faq/" class="uael-menu-item">FAQ</a></li>
                            <li id="menu-item-3088"
                              :class="[path=='/about-us/'?'current-menu-item':'']"
                              class="menu-item menu-item-type-custom menu-item-object-custom parent uael-creative-menu"><a
                                href="/about-us/" class="uael-menu-item">About Us</a></li>
                        <li id="menu-item-3027"
                          class="menu-item menu-item-type-custom menu-item-object-custom parent uael-creative-menu"><a
                            href="/contact-us/" class="uael-menu-item">Contact Us</a></li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </header>
</template>

<script setup>
  import { onMounted, ref, watch } from "vue";
  import { useRouter } from 'vue-router'
  const { currentRoute } = useRouter();

  onMounted(() => {
  })
  const path = ref()
  watch(() => currentRoute.value, (to, from) => {
      path.value=to.path
    },{ immediate:true})
</script>
<style src="@/assets/css/solid.css"></style>
<style scoped>
  :deep(.elementor-widget-uael-nav-menu .menu-item a.uael-menu-item:not(.elementor-button), .elementor-widget-uael-nav-menu .sub-menu a.uael-sub-menu-item) {
      color: #e9ae2a;
  }
  .main-menu-wrap .uael-nav-menu li.current-menu-item {
    background: url("@/assets/images/hero-shape.png") top center/ 18px no-repeat;
  }

  .main-menu-wrap .uael-nav-menu li a {
    outline: 0px !important;
  }

  .size_table .uael-table thead th {
    background: #E9AE2A;
  }

  .size_table .uael-table {
    border: none !important;
  }

  .size_table .uael-table thead th:first-child {
    border-top-left-radius: 10px;
  }

  .size_table .uael-table thead th:last-child {
    border-top-right-radius: 10px;
  }

  .size_table .uael-table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
  }

  .size_table .uael-table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
  }

  .size_table .uael-table tbody tr.uael-table-row td:first-child,
  .size_table .uael-table tbody tr:last-child td {
    background: #1E201F;
  }

  .size_table .uael-table tbody tr:last-child td span {
    font-weight: 700;
  }

  .size_table .uael-table tbody tr:last-child td {
    border-bottom: none !important;
  }

  .one-pase-row .elementor-column.elementor-col-50 {
    padding: 0 15px;
  }

  .uael-nav-menu .sub-arrow {
    color: #E9AE2A;
  }

  @media (min-width: 1025px) {
                .menu-item ul.sub-menu {
                    padding: 15px 0;
                }

                .menu-item ul.sub-menu::before {
                    position: absolute;
                    left: 35px;
                    top: -7px;
                    width: 0;
                    height: 0;
                    content: '';
                    border-left: 9px solid transparent;
                    border-right: 9px solid transparent;
                    border-bottom: 9px solid #151616;
                }
            }

            .menu-item ul.sub-menu li.menu-item {
                border-radius: 0px !important;
            }

            .menu-item ul.sub-menu li.menu-item a.uael-sub-menu-item {
                background: url(@/assets/images/icons/play.svg) no-repeat;
                background-position: center left 20px;
                background-size: 4px;
            }

            .menu-item ul.sub-menu li.menu-item:hover a.uael-sub-menu-item {
                background: url(@/assets/images/icons/play-b.svg) no-repeat;
                background-position: center left 20px;
                background-size: 4px;
            }

            .toggleable .uael-rbs-slider {
                background: #303030 !important;
                border: 1px solid #424242;
                width: 100%;
                height: 100%;
            }

            .toggleable .uael-rbs-slider::before {
                width: 25.74px !important;
                height: 25.74px !important;
                background: #FFCE00 !important;
                top: -3px;
                left: 0px;
            }

            .toggleable .uael-sec-1 {
                margin-right: 15px !important;
                text-align: center;
            }

            .toggleable .uael-sec-2 {
                margin-left: 15px !important;
                text-align: center;
            }

            .toggleable .uael-rbs-switch-label {
                width: 51.47px;
                height: 22px;
            }

            .main-menu-wrap .uael-nav-menu li ul.sub-menu .menu-item {
                background-image: none;
            }
            .child-menu-item{
              padding-left: 30px !important;
            }
            .elementor-10 .elementor-element.elementor-element-9815ae6 .sub-menu a.uael-sub-menu-item:hover, .elementor-10 .elementor-element.elementor-element-9815ae6 nav.uael-dropdown li a.uael-menu-item:not(.elementor-button):hover, .elementor-10 .elementor-element.elementor-element-9815ae6 nav.uael-dropdown li a.uael-sub-menu-item:not(.elementor-button):hover, .elementor-10 .elementor-element.elementor-element-9815ae6 .uael-nav-menu nav.uael-dropdown-expandible li a.uael-menu-item:hover, .elementor-10 .elementor-element.elementor-element-9815ae6 .uael-nav-menu nav.uael-dropdown-expandible li a.uael-sub-menu-item:hover{
              background-color: #FFCE00;
              color: #151616;
            }
</style>