import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import "@/assets/css/tailwindcss.css"
import 'bootstrap/dist/css/bootstrap.css'
import 'maz-ui/styles'


import '@/assets/js/utilities.js'
import '@/assets/js/owl.carousel.min.js'
import '@/assets/js/stat.js'
// import '@/assets/js/popper.min.js'
import '@/assets/js/htbbootstrap.js'
import '@/assets/js/waypoints.js'
import '@/assets/js/astra-addon-670426b26202d2-49446749.js'
import '@/assets/js/purify.min.js'

import '@/assets/js/htmega-widgets-active.js'
// import '@/assets/js/uael-frontend.min.js'
// import '@/assets/js/frontend.min.js'
// import '@/assets/js/webpack.runtime.min.js'
// import '@/assets/js/minified/accordion.8799675460c73eb48972.bundle.min.js'
// import '@/assets/js/frontend-modules.min.js'
// import 'bootstrap/dist/js/bootstrap.min.js'

import { installToaster, ToasterOptions } from 'maz-ui'

const app = createApp(App)
app.use(router).mount('#app')

// DEFAULT OPTIONS
const toasterOptions = {
  position: 'center-top',
  timeout: 10_000,
  persistent: false,
}

app.use(installToaster, toasterOptions)
