<template>
  <div class="hfeed site" id="page">
    <HeaderLayout />
    <router-view />
    <FooterLayout />
  </div>
  <PopupLayout />
  <cky-consent />
  <top/>
</template>

<script setup>
import HeaderLayout from '@/components/header.vue';
import FooterLayout from '@/components/footer.vue';
import PopupLayout from '@/components/popup.vue';
import CkyConsent from '@/components/cky-consent.vue';
import Top from '@/components/top.vue';

import { onMounted } from "vue";

onMounted(() => {
  
  $(document).ready(function () {
    // require("@/assets/js/pum-site-scripts.js")
  })
})
</script>

<style></style>